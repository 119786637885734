<template>
    <div v-if="!$wait.is('loading-page')">
        <y-page-head
            :title="name"
            icon="md-office-building"
        >
            <template slot="actions">
                <div class="buttons">
                    <y-button
                        v-if="canEdit"
                        @click="openGlobalModal('Icib-OrganizationsEditModal', { id: $route.params.id })"
                    >
                        {{ $t('button.edit') }}
                    </y-button>
                </div>
            </template>
        </y-page-head>

        <main>
            <y-panel>
                <y-view-field :label="$t('fields.status')">
                    <y-status-tag :status="status" />
                </y-view-field>

                <y-view-field :label="$t('fields.organization_name')">
                    {{ name }}
                </y-view-field>

                <y-view-field :label="$t('fields.organization_type')">
                    {{ type }}
                </y-view-field>

                <y-view-field :label="$t('fields.organization_registered_no')">
                    {{ registeredNo | digits($i18n.locale) }}
                </y-view-field>

                <y-view-field :label="$t('fields.organization_registered_at')">
                    {{ registeredAt | toJalaali(dateFormat) | digits($i18n.locale) }}
                </y-view-field>

                <y-view-field :label="$t('fields.country')">
                    {{ country }}
                </y-view-field>

                <y-view-field :label="$t('fields.province')">
                    {{ province }}
                </y-view-field>

                <y-view-field :label="$t('fields.city')">
                    {{ city }}
                </y-view-field>

                <y-view-field :label="$t('fields.unique_id')">
                    {{ nationalID | digits($i18n.locale) }}
                </y-view-field>

                <y-view-field :label="$t('fields.economical_code')">
                    {{ economicalCode | digits($i18n.locale) }}
                </y-view-field>

                <y-view-field :label="$t('fields.official_newspaper')">
                    <a
                        v-if="officialNewspaper"
                        id="rrkImage"
                        class="company-profile--attachment"
                        target="blank"
                        :href="officialNewspaper"
                    >
                        <i class="icon md-attachment" />
                    </a>
                </y-view-field>

                <y-view-field :label="$t('fields.address')">
                    {{ address | digits($i18n.locale) }}
                </y-view-field>

                <y-view-field :label="$t('fields.postal_code')">
                    {{ postalCode | digits($i18n.locale) }}
                </y-view-field>

                <y-view-field :label="$t('fields.tel')">
                    {{ phoneNumber | digits($i18n.locale) }}
                </y-view-field>

                <y-view-field :label="$t('fields.ceo_phone_number')">
                    {{ ceoPhoneNumber | digits($i18n.locale) }}
                </y-view-field>

                <y-view-field :label="$t('fields.activity_field')">
                    {{ activityField }}
                </y-view-field>

                <y-view-field :label="$t('fields.logo')">
                    <y-view-field-widget-logo
                        v-if="logo"
                        :logo="logo"
                    />
                </y-view-field>

                <y-view-field :label="$t('fields.organization_username')">
                    {{ userName }}
                </y-view-field>
            </y-panel>
        </main>
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import GlobalMixin from '@/mixins/Global';

    import YViewField from '@/modules/icib/components/ViewField';
    import YViewFieldWidgetLogo from '@/modules/icib/components/ViewFieldWidgetLogo';
    import YStatusTag from '@/modules/icib/components/StatusTag';
    import YPageHead from '@deps/PageHead';
    import SimpleLightbox from 'simple-lightbox';

    export default {

        name: 'CompanyProfile',

        components: {
            YViewField,
            YViewFieldWidgetLogo,
            YStatusTag,
            YPageHead,
        },

        mixins: [PageMixin, GlobalMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                id        : null,
                company   : null,
                dateFormat: this.$i18n.locale === 'fa' ? 'jYYYY/jM/jD' : 'YYYY/M/D',
                lightbox  : null,
            };
        },

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('icib-organizations-list', this.$t('breadcrumbs.organizations')),
                this.$bc(this.$t('breadcrumbs.organization_view')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('icib.organization.view_organization'),
            };
        },

        computed: {

            /**
             * Check if user can edit
             */
            canEdit() {
                return this.hasGlobalModal('Icib-OrganizationsEditModal');
            },

            /**
             * Return Company Name
             */
            name() {
                return (this.company && this.company.name_full) || '';
            },

            /**
             * Return Company Type
             */
            type() {
                return (this.company && this.company.type_title) || '';
            },

            /**
             * Return Company Status
             */
            status() {
                return (this.company && this.company.status_title) || '';
            },

            /**
             * Return Company Registered No
             */
            registeredNo() {
                return (this.company && this.company.registered_no) || '';
            },

            /**
             * Return Company Registration Date
             */
            registeredAt() {
                return (this.company && this.company.registered_at) || '';
            },

            /**
             * Return National ID
             */
            nationalID() {
                return (this.company && this.company.code_melli) || '';
            },

            /**
             * Return Economical Code
             */
            economicalCode() {
                return (this.company && this.company.economy_code) || '';
            },

            /**
             * Return Company Address
             */
            address() {
                return (this.company && this.company.address) || '';
            },

            /**
             * Return Company Postal Code
             */
            postalCode() {
                return (this.company && this.company.postal_code) || '';
            },

            /**
             * Return Company Phone Number
             */
            phoneNumber() {
                return (this.company && this.company.tel) || '';
            },

            /**
             * Return CEO Phone Number
             */
            ceoPhoneNumber() {
                return (this.company && this.company.mobile) || '';
            },

            /**
             * Return Company Activity Field
             */
            activityField() {
                return (this.company && this.company.activity_field_title) || '';
            },

            /**
             * Return Company User Name
             */
            userName() {
                return (this.company && this.company.username) || '';
            },

            /**
             * Return Company Logo Url
             */
            logo() {
                return (this.company && this.company.logo && this.company.logo[0] && this.company.logo[0].link);
            },

            /**
             * Return Url of the Official Newspaper
             */
            officialNewspaper() {
                return (this.company && this.company.rrk && this.company.rrk[0] && this.company.rrk[0].link);
            },

            /**
             * Return Company Country
             */
            country() {
                return (this.company && this.company.division_titles && this.company.division_titles.country) || '';
            },

            /**
             * Return Company Country
             */
            province() {
                return (this.company && this.company.division_titles && this.company.division_titles.province) || '';
            },

            /**
             * Return Company Country
             */
            city() {
                return (this.company && this.company.division_titles && this.company.division_titles.city) || '';
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.lightbox = new SimpleLightbox({ elements: '#rrkImage' }, {
                showCounter: true,
            });

            // Check events
            this.$root.$on('refreshGrid', () => {
                this.reFetch();
            });
        },

        methods: {
            /**
             * Fetch organization profile data
             */
            fetch() {
                this.id = this.$route.params.id;

                return this.$services.Icib.organizationAdminDetails({ id: this.id }).then((response) => {
                    this.$set(this, 'company', response.data.results);
                    this.registerGlobalModals(response.data.metadata.modals);
                }).catch((error) => {
                    this.handleError(error);
                });
            },
        },
    };

</script>
