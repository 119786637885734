<template>
    <div class="view-field-widget-logo">
        <img
            :src="logo"
            :alt="alt"
        >
    </div>
</template>

<script>
    export default {
        name: 'YViewFieldWidgetLogo',
        
        props: [
            // eslint-disable-next-line vue/require-prop-types
            'logo',
            // eslint-disable-next-line vue/require-prop-types
            'alt',
        ],
    };
</script>
