<template>
    <div
        v-if="number"
        class="y-split-number"
    >
        <span
            v-for="(i, index) in splitNumbersToDigit(number)"
            :key="index"
            :class="wrapperClass"
        >
            {{ i | digits }}
        </span>
    </div>
</template>

<script>

    export default {
        name: 'YSplitNumber',

        props: {
            number: {
                type: [String, Number],
            },
            wrapperClass: {
                type: String,
            },
        },

        methods: {
            /**
             * Split Numbers to show each in box
             *
             * @param n
             */
            splitNumbersToDigit(n) {
                return (n + '').split('').map((i) => Number(i)); // eslint-disable-line
            },
        },
    };

</script>
