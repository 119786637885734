<template>
    <div class="status-tag">
        {{ status }}
    </div>
</template>

<script>
    export default {
        name: 'YStatusTag',

        props: [
            // eslint-disable-next-line vue/require-prop-types
            'status',
        ],
    };
</script>
