<template>
    <div class="view-field">
        <div class="view-field--label">
            {{ label }}
        </div>
        <div class="view-field--value">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'YViewField',

        props: [
            // eslint-disable-next-line vue/require-prop-types
            'label',
        ],
    };
</script>
