<template>
    <div class="invoice-wrapper">
        <y-loading
            :active="$wait.is('loading-invoice')"
            height="500px"
        >
            <div
                id="invoice"
                ref="invoice"
                class="invoice"
            >
                <!-- Invoice Title -->
                <div class="row align-items-center invoice-row invoice-header">
                    <div class="col-print-3 col-8 col-md-9">
                        <h3 class="invoice-title">
                            {{ $t('icib.invoice.title') }}
                        </h3>
                    </div>
                    <div class="col-print-1 col-4 col-md-3">
                        <div class="row">
                            <div class="col-12">
                                <p class="invoice-block">
                                    <span class="invoice-block-key">{{ $t('icib.invoice.number') }}</span>
                                    <span class="invoice-block-value">{{ invoice.invoice_number | digits }}</span>
                                </p>
                            </div>
                            <div class="col-12">
                                <p class="invoice-block">
                                    <span class="invoice-block-key">{{ $t('icib.invoice.date') }}</span>
                                    <span class="invoice-block-value">{{ invoice.created_at | date('YYYY/MM/DD', $i18n.locale) | digits }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Seller Details -->
                <y-invoice-info
                    v-if="seller"
                    :title="$t('icib.invoice.seller_title')"
                    :item="seller"
                />

                <!-- Buyer Details -->
                <y-invoice-info
                    v-if="buyer"
                    :title="$t('icib.invoice.buyer_title')"
                    :item="buyer"
                />

                <!-- Product Details -->
                <div
                    v-if="product"
                    class="row invoice-row"
                >
                    <div class="col-12 invoice-subtitle-bg">
                        <h3 class="invoice-subtitle">
                            {{ $t('icib.invoice.product_title') }}
                        </h3>
                    </div>
                    <div class="col-12 invoice-table">
                        <div class="invoice-table-container">
                            <div class="table-wrapper">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th
                                                v-for="column in product_table_title"
                                                :key="column.id"
                                                class="invoice-block-key no-dots"
                                            >
                                                {{ column.title }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(row, index) in product"
                                            :key="index"
                                        >
                                            <td class="invoice-block-value">
                                                {{ (index + 1) | digits }}
                                            </td>
                                            <td class="invoice-block-value">
                                                {{ row.title | digits }}
                                            </td>
                                            <td class="invoice-block-value">
                                                {{ row.count | digits }}
                                            </td>
                                            <td class="invoice-block-value">
                                                {{ row.price | digits }}
                                            </td>
                                            <td class="invoice-block-value">
                                                {{ row.subtotal | digits }}
                                            </td>

                                            <td
                                                v-if="!isEditMode"
                                                class="invoice-block-value"
                                            >
                                                {{ row.discount | digits }}
                                            </td>
                                            <td
                                                v-else
                                                class="invoice-block-value"
                                            >
                                                <form
                                                    class="invoice-edit-discount-form"
                                                    @submit.prevent="editDiscount"
                                                >
                                                    <y-form-field
                                                        v-model="discount"
                                                        type="text"
                                                        no-label
                                                        name="discount"
                                                        class="mb0"
                                                    />
                                                </form>
                                            </td>

                                            <td class="invoice-block-value">
                                                {{ row.tax_percent + `%` | digits }}
                                            </td>
                                            <td class="invoice-block-value">
                                                {{ row.total | digits }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td class="invoice-block-key no-dots">
                                                {{
                                                    $t('icib.invoice.product.total') }}
                                            </td>
                                            <td
                                                colspan="6"
                                                class="invoice-block-value text-right"
                                            >
                                                <span class="invoice-block-key no-dots">{{ $t('icib.invoice.product.total_text') + ' ' }}</span>
                                                {{ Num2persian(Number(product[0].total)) + ' ' + $t('icib.invoice.unit') }}
                                            </td>
                                            <td class="invoice-block-value">
                                                {{ product.total | digits }}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Signs and Description -->
                <div class="row invoice-row">
                    <div class="col-print-2 col-6">
                        <p class="invoice-block is-sign">
                            <span class="invoice-block-key no-dots">{{ $t('icib.invoice.seller_sign') }}</span>
                        </p>
                    </div>
                    <div class="col-print-2 col-6">
                        <p class="invoice-block is-sign">
                            <span class="invoice-block-key no-dots">{{ $t('icib.invoice.buyer_sign') }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="row invoice-row is-actions">
                <div class="col-12 invoice-button-row">
                    <y-button
                        v-if="isEditMode"
                        class="invoice-button"
                        color="red"
                        @click="isEditMode = false"
                    >
                        {{ $t('icib.invoice.cancel_edit') }}
                    </y-button>
                    <y-button
                        v-if="isEditMode"
                        class="invoice-button"
                        color="green"
                        @click="editDiscount"
                    >
                        {{ $t('icib.invoice.save_discount') }}
                    </y-button>

                    <y-button
                        v-show="!isEditMode"
                        v-if="isEditable"
                        class="invoice-button"
                        color="blue"
                        @click="isEditMode = true"
                    >
                        {{ $t('icib.invoice.edit_discount') }}
                    </y-button>

                    <y-button
                        class="invoice-button"
                        color="yellow"
                        @click="printSection('invoice')"
                    >
                        {{ $t('icib.invoice.print') }}
                    </y-button>
                </div>
            </div>
        </y-loading>
    </div>
</template>

<script>

    import YFormField from '@deps/form/FormField';
    import Num2persian from '@/modules/icib/mixins/digitsToPersianWords';
    import { YButton } from '@deps';
    import YInvoiceInfo from '@/modules/icib/components/InvoiceInfo';

    export default {
        name: 'InvoiceSingle',

        components: {
            YButton,
            YInvoiceInfo,
            YFormField,
        },

        mixins: [
            Num2persian,
        ],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('icib-invoice-single', this.$t('breadcrumbs.invoice_single')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('icib.invoice.view_invoice'),
            };
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                id        : this.$route.params.id,
                isEditMode: false,
                isEditable: null,
                discount  : null,

                seller : [],
                buyer  : [],
                invoice: [],

                product            : null,
                product_table_title: [
                    {
                        title: this.$t('icib.invoice.product.row_no'),
                        id   : 'row_no',
                    },
                    {
                        title: this.$t('icib.invoice.product.name'),
                        id   : 'name',
                    },
                    {
                        title: this.$t('icib.invoice.product.number'),
                        id   : 'number',
                    },
                    {
                        title: this.$t('icib.invoice.product.unit_price'),
                        id   : 'unit_price',
                    },
                    {
                        title: this.$t('icib.invoice.product.total_fee'),
                        id   : 'total_fee',
                    },
                    {
                        title: this.$t('icib.invoice.product.discount'),
                        id   : 'discount',
                    },
                    {
                        title: this.$t('icib.invoice.product.tax'),
                        id   : 'tax',
                    },
                    {
                        title: this.$t('icib.invoice.product.total_plus_tax'),
                        id   : 'total_plus_taxumber',
                    },

                ],
            };
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.fetch();
        },

        methods: {

            /**
             * fetch data
             */
            fetch() {
                this.$wait.start('loading-invoice');
                const { id } = this.$route.params;
                // Get Items
                this.$services.Icib.invoiceFetch({ id }).then((response) => {
                    this.isEditable = response.data.metadata.is_editable;
                    this.seller = response.data.results.seller_information;
                    this.buyer = response.data.results.buyer_information;
                    this.product = response.data.results.products_information;
                    this.invoice = response.data.results.factor_information;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-invoice');
                });
            },

            /**
             * Edit Discount
             *
             */
            editDiscount() {
                if (!this.isEditable) {
                    return;
                }
                const params = {
                    id    : this.$route.params.id,
                    amount: this.discount,
                };
                this.$services.Icib.invoiceDiscountSave(params).then((response) => {
                    this.product = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.isEditMode = false;
                });
            },

            /**
             * Print Section
             */
            printSection() {
                const body = document.getElementById('invoice').innerHTML;
                const WinPrint = window.open('', '_blank', 'top=0,right=0,width=1600,height=794,toolbar=0,scrollbars=0,status=0,fullscreen=1');
                WinPrint.document.write(`<!DOCTYPE html>
                <html dir="rtl" lang="fa">
                  <head>
                    <style type="text/css">${this.$config('print.typo')}</style>
                    <style type="text/css">${this.$config('print.col')}</style>
                    <style type="text/css">${this.$config('print.table')}</style>
                    <style type="text/css">${this.$config('print.invoice')}</style>
                    <title>${document.title}</title>
                  </head>
                  <body class="print">
                    ${body}
                  </body>
                </html>`);
                setTimeout(() => {
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            },
        },
    };

</script>
