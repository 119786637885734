<template>
    <div class="row invoice-row">
        <div class="col-12 invoice-subtitle-bg">
            <h3 class="invoice-subtitle">
                {{ title }}
            </h3>
        </div>
        <div
            v-if="item"
            class="col-12"
        >
            <div class="row invoice-block-row">
                <div class="col-print-1 col-12 col-sm-4 col-md-6 col-lg-2">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('icib.invoice.name') }}</span>
                        <span class="invoice-block-value">{{ item.name_first ? item.name_first : item.organization_name }}</span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-8 col-md-6 col-lg-4">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('icib.invoice.eco_no') }}</span>
                        <span class="invoice-block-value in-print">{{ item.economy_code | digits }}</span>
                        <span class="invoice-block-value has-digits">
                            <y-split-number
                                :number="item.economy_code"
                                wrapper-class="invoice-block-value-digit"
                            />
                        </span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-4 col-md-6 col-lg-3">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('icib.invoice.reg_no') }}</span>
                        <span class="invoice-block-value in-print">{{ item.registered_no | digits }}</span>
                        <span class="invoice-block-value has-digits">
                            <y-split-number
                                :number="item.registered_no"
                                wrapper-class="invoice-block-value-digit"
                            />
                        </span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-8 col-md-6 col-lg-3">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('icib.invoice.national_no') }}</span>
                        <span class="invoice-block-value in-print">{{ item.national_id | digits }}</span>
                        <span class="invoice-block-value has-digits">
                            <y-split-number
                                v-if="item"
                                :number="item.national_id"
                                wrapper-class="invoice-block-value-digit"
                            />
                        </span>
                    </p>
                </div>
                <div
                    v-if="item.division"
                    class="col-print-1 col-12 col-sm-4 col-md-6 col-lg-2"
                >
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('icib.invoice.state') }}</span>
                        <span class="invoice-block-value">{{ item.division ? item.division.province : `-` }}</span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-8 col-md-6 col-lg-4">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('icib.invoice.county') }}</span>
                        <span class="invoice-block-value">{{ item.division ? item.division.city : `-` }}</span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-4 col-md-6 col-lg-3">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('icib.invoice.city') }}</span>
                        <span class="invoice-block-value">{{ item.division && item.division.neighbourhood ? item.division.neighbourhood : `-` }}</span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-8 col-md-6 col-lg-3">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('icib.invoice.zipcode') }}</span>
                        <span class="invoice-block-value in-print">{{ item.postal_code | digits }}</span>
                        <span class="invoice-block-value has-digits">
                            <y-split-number
                                :number="item.postal_code"
                                wrapper-class="invoice-block-value-digit"
                            />
                        </span>
                    </p>
                </div>
                <div class="col-print-3 col-12 col-sm-12 col-md-6 col-lg-9">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('icib.invoice.address') }}</span>
                        <span class="invoice-block-value">{{ item.address }}</span>
                    </p>
                </div>
                <div class="col-print-1 col-12 col-sm-12 col-md-6 col-lg-3">
                    <p class="invoice-block">
                        <span class="invoice-block-key">{{ $t('icib.invoice.phone') }}</span>
                        <span class="invoice-block-value">
                            {{ (item.phone ? item.phone : item.tel ? item.tel : `-`) | digits }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import YSplitNumber from '@/modules/icib/components/SplitNumbers';

    export default {
        name: 'YInvoiceInfo',

        components: {
            YSplitNumber,
        },

        props: {
            title: {
                type: String,
            },
            item: {
                type: [Array, Object],
            },
        },
    };

</script>
