/*-----------------------------------------------------------------
- Convert Digits to Letters mixin
-----------------------------------------------------------------*/
export default {

    name: 'digitsToLettersMixin',

    /**
     * @inheritDoc
     */
    data() {
        return {
            delimiter: ' و ',
            zero     : 'صفر',
            negative : 'منفی ',
            letters  : [
                ['', 'یک', 'دو', 'سه', 'چهار', 'پنج', 'شش', 'هفت', 'هشت', 'نه'],
                ['ده', 'یازده', 'دوازده', 'سیزده', 'چهارده', 'پانزده', 'شانزده', 'هفده', 'هجده', 'نوزده', 'بیست'],
                ['', '', 'بیست', 'سی', 'چهل', 'پنجاه', 'شصت', 'هفتاد', 'هشتاد', 'نود'],
                ['', 'یکصد', 'دویست', 'سیصد', 'چهارصد', 'پانصد', 'ششصد', 'هفتصد', 'هشتصد', 'نهصد'],
                ['', ' هزار', ' میلیون', ' میلیارد', ' بیلیون', ' بیلیارد',
                    ' تریلیون', ' تریلیارد', 'کوآدریلیون', ' کادریلیارد',
                    ' کوینتیلیون', ' کوانتینیارد', ' سکستیلیون', ' سکستیلیارد', ' سپتیلیون', 'سپتیلیارد', ' اکتیلیون',
                    ' اکتیلیارد', ' نانیلیون', ' نانیلیارد', ' دسیلیون',
                    ' دسیلیارد'],
            ],
            decimalSuffixes: ['', 'دهم', 'صدم', 'هزارم', 'ده‌هزارم', 'صد‌هزارم', 'میلیونوم', 'ده‌میلیونوم', 'صدمیلیونوم', 'میلیاردم', 'ده‌میلیاردم', 'صد‌‌میلیاردم'],
        };
    },

    methods: {

        /**
         * Clear number and split to 3 sections
         *
         * @param {*} num
         */
        prepareNumber(num) {
            let Out = num;

            if (typeof Out === 'number') {
                Out = Out.toString();
            }

            const NumberLength = Out.length % 3;

            if (NumberLength === 1) {
                Out = '00'.concat(Out);
            } else if (NumberLength === 2) {
                Out = '0'.concat(Out);
            } // Explode to array

            return Out.replace(/\d{3}(?=\d)/g, '$&*').split('*');
        },

        /**
         * Convert Three Numbers to Letters
         *
         * @param num
         */
        threeNumbersToLetter(num) {
            // return zero
            if (parseInt(num, 10) === 0) {
                return '';
            }

            const parsedInt = parseInt(num, 10);

            if (parsedInt < 10) {
                return this.letters[0][parsedInt];
            }

            if (parsedInt <= 20) {
                return this.letters[1][parsedInt - 10];
            }

            if (parsedInt < 100) {
                const _one = parsedInt % 10;

                const _ten = (parsedInt - _one) / 10;

                if (_one > 0) {
                    return this.letters[2][_ten] + this.delimiter + this.letters[0][_one];
                }

                return this.letters[2][_ten];
            }

            const one = parsedInt % 10;
            const hundreds = (parsedInt - (parsedInt % 100)) / 100;
            const ten = (parsedInt - (hundreds * 100 + one)) / 10;
            const out = [this.letters[3][hundreds]];
            const SecondPart = ten * 10 + one;

            if (SecondPart > 0) {
                if (SecondPart < 10) {
                    out.push(this.letters[0][SecondPart]);
                } else if (SecondPart <= 20) {
                    out.push(this.letters[1][SecondPart - 10]);
                } else {
                    out.push(this.letters[2][ten]);

                    if (one > 0) {
                        out.push(this.letters[0][one]);
                    }
                }
            }

            return out.join(this.delimiter);
        },

        /**
         * Convert Decimal part
         *
         * @param decimalPart
         * @returns {string}
         * @function Object() { [native code] }
         */
        convertDecimalPart(decimalPart) {
            // Clear right zero
            decimalPart = decimalPart.replace(/0*$/, ''); //eslint-disable-line

            if (decimalPart === '') {
                return '';
            }

            if (decimalPart.length > 11) {
                decimalPart = decimalPart.substr(0, 11); //eslint-disable-line
            }

            return ' ممیز ' + this.Num2persian(decimalPart) + ' ' + this.decimalSuffixes[decimalPart.length]; //eslint-disable-line
        },

        /**
         * Main function
         *
         * @param input
         * @returns {string}
         * @function Object() { [native code] }
         */
        Num2persian(input) {
            // Clear Non digits
            input = input.toString().replace(/[^0-9.-]/g, ''); // eslint-disable-line
            let isNegative = false;
            const floatParse = parseFloat(input); // return zero if this isn't a valid number

            if (isNaN(floatParse)) { // eslint-disable-line
                return this.zero;
            } // check for zero


            if (floatParse === 0) {
                return this.zero;
            } // set negative flag:true if the number is less than 0


            if (floatParse < 0) {
                isNegative = true;
                input = input.replace(/-/g, ''); // eslint-disable-line
            } // Declare Parts


            let decimalPart = '';
            let integerPart = input;
            const pointIndex = input.indexOf('.'); // Check for float numbers form string and split Int/Dec

            if (pointIndex > -1) {
                integerPart = input.substring(0, pointIndex);
                decimalPart = input.substring(pointIndex + 1, input.length);
            }

            if (integerPart.length > 66) {
                return 'خارج از محدوده';
            } // Split to sections


            const slicedNumber = this.prepareNumber(integerPart); // Fetch Sections and convert

            const Output = [];
            const SplitLength = slicedNumber.length;

            for (let i = 0; i < SplitLength; i += 1) {
                const SectionTitle = this.letters[4][SplitLength - (i + 1)];
                const converted = this.threeNumbersToLetter(slicedNumber[i]);

                if (converted !== '') {
                    Output.push(converted + SectionTitle);
                }
            } // Convert Decimal part


            if (decimalPart.length > 0) {
                decimalPart = this.convertDecimalPart(decimalPart);
            }

            return (isNegative ? this.negative : '') + Output.join(this.delimiter) + decimalPart;
        },
    },
};
